import React, {useEffect, useRef} from "react"
import styled from "styled-components"
import "react-datepicker/dist/react-datepicker.css";
import DatePicker, { registerLocale } from "react-datepicker"
import pl from "date-fns/locale/pl"
import Calendar from "../../images/OtherAssets/calendar.svg"
import {Slide} from "react-reveal";
import {isMobile} from 'react-device-detect';
import moment from "moment";
import {ErrorMessageText} from "../sharedComponents/SharedComponents";
registerLocale("pl", pl)

const StyledLabel = styled.label`
	display: flex;
	flex-direction: column;
	width: 100%;
	font-size: 16px;
    margin-top: ${({isFirst}) => isFirst ? '0' : '30px'};
	font-weight: ${({theme}) => theme.fontWeight.black};
	line-height: 20px;
	color: ${({theme}) => theme.colors.formFontDarkBlue};
	&.first-component {
		margin-top: 0;
	}
    @media(min-width: 414px) {
  		width: 353px;
    }
    @media(min-width: 536px) {
  		width: 476px;
    }
	@media(min-width: 768px) {
		width: 520px;
	}
	@media(min-width: 1024px) {
		margin-top: ${({isMarginSmall, isFirst}) => isMarginSmall ? '20px' : isFirst ? '0' : '50px'};
	}
	@media(min-width: 1920px) {
		width: 550px;
	}
`;

const StyledInputWrapper = styled.div`
	position: relative;
	margin-top: 15px;
	max-width: 550px;
	font-weight: ${({theme}) => theme.fontWeight.semiBold};
	  
	.datepicker-custom {
		position: relative;
		z-index:99999999;
		background-color: ${({theme}) => theme.colors.formWhite};
		border: none;
		height: 50px;
		border-radius: 10px;
		color: ${({theme}) => theme.colors.formFontDarkBlue};
		font-size: 16px;
		line-height: 20px;
		font-family: ${({theme}) => theme.fontFamily.mainFont};
		font-weight: ${({theme}) => theme.fontWeight.semiBold};
		outline: none;
		padding: 15px 30px;
		width: 100%;
		@media(min-width: 768px) {	
			height: 60px;
		}
		&:hover {
			cursor: pointer;
		}
		&::placeholder {
			color: ${({theme}) => theme.colors.formLightGrey};
			font-weight: ${({theme}) => theme.fontWeight.regular};
			text-transform: lowercase;
			font-size: 16px;
  	}
  	
	    &:not(output):-moz-ui-invalid {
	        box-shadow: none;
	    }
 	}
 
    .react-datepicker-wrapper {
		width: 100%;
  	}
 
 	&.datepicker {
		.react-datepicker {
			background-color: ${({theme}) => theme.colors.formWhite};
			border: none;
			border-radius: 10px;
			width: 100%;	
			&__input-container {
				border: 1px solid ${({ theme }) => theme.colors.formBlue};
				border-radius: 10px;
				max-width: 550px;
				&:hover {
					border: 1px solid ${({ theme }) => theme.colors.formFontDarkBlue};
					cursor: pointer;
				}
			}
			&__navigation {
				outline: none;
			}
			&__navigation--next {
				border-left-color: ${({theme}) => theme.colors.formFontDarkBlue};
			}
			&__navigation--previous {
				border-right-color: ${({theme}) => theme.colors.formFontDarkBlue};
			}
			&__day--keyboard-selected{
				background-color: transparent;
			}
			&__month-option, &__year-option {
				line-height: 24px;
				color: ${({theme}) => theme.colors.formFontDarkBlue};
				&:hover {
					background-color: ${({theme}) => theme.colors.formAlwisOrange};
					color: ${({theme}) => theme.colors.formWhite};
				}
			}
			&__month-read-view, &__year-read-view {
				min-width: 110px;
			}
			&__month-dropdown, &__year-dropdown {
				background-color: ${({theme}) => theme.colors.formWhite};
				border: 1px solid ${({theme}) => theme.colors.formBlue};
				border-radius: 10px;
				color: ${({theme}) => theme.colors.formGreyNumber};
				font-size: 13px;
			}
			&__month-read-view--down-arrow, &__year-read-view--down-arrow {
				border-top-color: ${({theme}) => theme.colors.formAlwisOrange};
				margin-left: 10px;
			}
			&__month-dropdown-container--scroll, &__year-dropdown-container--scroll {
				background-color: ${({theme}) => theme.colors.formWhite};
				border: 1px solid ${({theme}) => theme.colors.formBlue};
				color: ${({theme}) => theme.colors.formFontDarkBlue};
				border-radius: 4px;
				outline: none;
				margin: 10px 4px;
				padding-right: 10px;
			}
			&__navigation--years-upcoming {
				border-bottom-color: ${({theme}) => theme.colors.formFontDarkBlue};
			}
			&__navigation--years-previous {
				border-top-color: ${({theme}) => theme.colors.formFontDarkBlue};
			}
			&__triangle {
				display: none;
			}
			&-popper {
				width: 100%;
				margin-top: 0;
				z-index: 11;
			}
			&__month-container {
				width: 100%;
				border: 1px solid ${({theme}) => theme.colors.formBlue};
				border-radius: 10px 10px 0 0;
			}
			&__header {
				background-color: ${({theme}) => theme.colors.formInputGreyBackground};
				border-bottom: none;
				border-radius: 10px 10px 0 0;
			}
			&__header__dropdown--scroll {
				margin-bottom: 10px;
			}
			&__current-month {
				padding-bottom: 7px;
			}
			&__day-name  {
				padding-bottom:4px;
			}
			&__month {
				margin: 15px 0 0;
			}
			&__current-month, &__day-name  {
				color:  ${({theme}) => theme.colors.formFontDarkBlue};
				font-size: 15px;
				line-height: 19px;
				font-weight: ${({ theme }) => theme.fontWeight.semiBold};
        		font-family: ${({ theme }) => theme.fontFamily.mainFont};
			}
			&__day {
				width: calc(90%/ 7);
				color: ${({theme}) => theme.colors.formFontDarkBlue};
				font-size: 14px;
				line-height: 18px;
				font-weight: ${({theme}) => theme.fontWeight.black};
				font-family: ${({theme}) => theme.fontFamily.mainFont};
				padding: 4px 0;
				text-align: center;
				&-name {
					width: calc(90%/ 7);
				}
				
				&:hover {
					background-color: rgba(67, 108, 152, .4);
					border: none;
					border-radius: 0;
				}
				&--today {
					color: ${({theme}) => theme.colors.formWhite};
					background-color: ${({theme}) => theme.colors.formAlwisOrange};
					border-radius: 0;
				}
				&--disabled {
					color: ${({theme}) => theme.colors.formGreyNumber};
					&:hover {
						background-color: transparent;
					}
				}
				&--outside-month {
					color: ${({theme}) => theme.colors.formFontDarkBlue};
				}
	
				&--selected {
					background-color: ${({theme}) => theme.colors.formAlwisOrange};
					color :${({theme}) => theme.colors.formWhite};
					border: none;
					border-radius: 0;
				}
			}
		}
	}
		
`;

const Icon = styled.img`
	width: 22px;
	position: absolute;
	right: 22px;
	top: calc(50% - 10px);
`

const DatepickerAndErrorWrapper = styled.div`
	position: relative;
`

const CustomDatepickerReduxForm = ({input, label, type, className, selected, placeholder, placeholderText, defaultValue, locale, pickerClassName, isMarginSmall, onChange, isFirst, meta: {touched, error} }) => {
	// const { pickerClassName, isMarginSmall, isFirst } = props
	// const pickerRef = useRef(null)

	// useEffect(() => {
	// 	if (isMobile && pickerRef.current !== null) {
	// 		pickerRef.current.input.readOnly = true;
	// 	}
	// }, [isMobile, pickerRef]);

	return (
			<DatepickerAndErrorWrapper>
			<StyledLabel className={pickerClassName} isFirst={isFirst} isMarginSmall={isMarginSmall} onClick={e => e.preventDefault()}>
				{label}
				<StyledInputWrapper className={`${pickerClassName}`}>
					<DatePicker {...input} locale={locale} selected={selected} placeholderText={placeholderText} className="datepicker-custom" onChange={date => input.onChange(moment(date).format("MM/DD/YYYY"))} />
					<Icon src={Calendar}/>
				</StyledInputWrapper>
			</StyledLabel>
			{touched && error && <ErrorMessageText>{error}</ErrorMessageText>}
			</DatepickerAndErrorWrapper>

	)
}

export default CustomDatepickerReduxForm

// const renderDatePicker = ({input, placeholder, defaultValue, meta: {touched, error} }) => (
// 	<div>
// 		<DatePicker {...input} dateForm="MM/DD/YYYY" selected={input.value ? moment(input.value) : null} />
// 		{touched && error && <span>{error}</span>}
// 	</div>
// );
//
// export default renderDatePicker

