import React from "react"
import {useSelector} from "react-redux";
import CleanFourthStep from "../components/fourthStep/CleanFourthStep";

const Step4 = () => {
	const isDataLoadFromLocalStorage = useSelector(state => state.loadDataFromLocalStorage.loadDataFromLocalstorage);
	return (
		<>
			<CleanFourthStep/>
		</>
	)
};

export default Step4
