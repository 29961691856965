import React from 'react'
import styled from "styled-components"
import { ErrorMessageText } from "../sharedComponents/SharedComponents";

const StyledCheckboxInput = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	position: relative;
	margin-right: 16px;
	height: calc(0.9 * (50vw - 38px));
	width: calc(50% - 8px);
	max-height: 130px;
	cursor: pointer;
	z-index: 0; 
	transition: opacity .3s;
	margin-top: 12px;
	@media(min-width: 320px) {
		width: 105px;
		height: 95px;
		max-height: unset;
		margin-right: 10px;
	}
	@media(min-width: 374px) {
		/* margin-right: 0px; */
	}
	@media(min-width: 414px) {
		width: 105px;
		height: 95px;
	}
	@media(min-width: 535px) {
		margin-right: 13px;
	}
	@media(min-width: 768px) {
		margin-top: 12px;
		height: 150px;
		width: 160px;
		margin-right: 20px;
	}
	@media(min-width: 1920px) {
		width: 170px;
	}
	p {
	    padding-left: 0;
		font-size: 13px;
    	line-height: 14px;
	    font-weight: 600;
	    color: ${({ theme }) => theme.colors.formFontDarkBlue};
	    z-index: 10;
	    position: absolute;
	    bottom: 6px;
	    text-align: center;
		width: calc(100% - 10px);
		margin-left: 5px;
		/* @media(min-width: 350px) {
			bottom: 12px;
		} */
		@media(min-width: 414px) {
			bottom: 6px;
			font-size: 13px;
    		line-height: 14px;
		}
	    @media(min-width: 768px) {
            font-size: 16px;
			letter-spacing: -0.2px;
	    	line-height: 20px;
	    	bottom: 12px;
	    }
        @media(min-width: 1920px) {
			text-align: center;
			width: 100%;
			margin-left: 0;
			padding: 0 5px;
  		}	
    }
	.custom-checkbox {
		opacity: 0;
	}
`;

const CheckboxLabel = styled.label`
		position: relative;
		background-color: ${({ checked, theme }) => checked ? theme.colors.formAlwisOrangeLightOrange : theme.colors.formTransparent};
		border-radius: 6px;
		border: ${({ checked, theme }) => checked ? "2px solid " + theme.colors.formAlwisOrange : "1px solid " + theme.colors.formBlue};
		box-shadow: ${({ checked }) => checked ? "0 3px 5px 0 rgba(0,0,0,0.09) !important" : ""};
		/* padding: ${({ checked }) => checked ? "25px 10px 10px;" : "24px 10px 9px;"}; */
		opacity: 1;
		z-index: 4;
		width: 100%;
		height: 100%;
		cursor: pointer;
		outline: none;
		&:hover {
			border: ${({ checked, theme }) => checked ? "2px solid" + theme.colors.formAlwisOrange : "1px solid" + theme.colors.formFontDarkBlue};
		}
        input {
			/* margin: -25px 0 0 -11px; */
			margin:0;
			width: calc(100%) !important;
			height: calc(100%)  !important;
			z-index: 99999!important;
			cursor: pointer;
			border: 1px solid black;
		}	
`;

export const ImageWrapper = styled.div`
	position: absolute;
	z-index: ${({ checked }) => checked ? 10 : 0};
	cursor: pointer;
	left: calc(50% - 20px);
	top: 7px;
	background-image: url(${props => props.image});
	background-repeat: no-repeat;
	background-size: contain;
    width: 40px;
	height: 50px;
	/* @media(min-width: 340px) {
		width: 52px;
		left: calc(50% - 26px);
	}
	@media(min-width: 375px) {
		width: 56px;
	} */
	@media(min-width: 414px) {
		width: 36px;
		left: calc(50% - 18px);
	}
	@media(min-width: 768px) {
		left: calc(50% - 29px);
		top: 35px;
		width: 57px;
		height: 71px;
	}
	@media(min-width: 1024px) {
		top: 35px;
	}
`
const CheckboxRelativeWrapper = styled.div`
	position: relative;
	margin-right:0!important;

`

const ErrorValidation = styled.p`
	font-size: 16px;
	display:block;
	color: ${({ theme }) => theme.colors.formAlwisOrange};
	position: relative;
	transform: translateY(107px);
	text-align: center;
&:nth-of-type(1){
	display:block;
}

`

export const ErrorMessageTextComponent = styled.p`
	font-size: 16px;
	color: ${({ theme }) => theme.colors.formAlwisOrange};
	position: absolute;
	bottom: -20px;
	transform: translateY(106px);
	// margin-top: ${({ isMovedTop }) => isMovedTop ? "-40px" : "0"};	
	@media (min-width:374px){
	transform: translateY(0px);
}`;

export const ErrorMessageTextComponent2 = styled.div`
	display:block;
	position: absolute;
	transform: translateY(106px);
	p {
		font-size: 16px;
		color: ${({ theme }) => theme.colors.formAlwisOrange};
	}

`;


// const ErrorNone = styled.span`
//  visibility: hidden;
// position:absolute; 
// `; 

const CustomCheckboxTiltReduxform = ({ input, meta: { error, touched }, children, required, checked, onClick, index, imageSource, tiltText }) => {
	return (
		<>
			<CheckboxRelativeWrapper>
				<StyledCheckboxInput onClick={onClick} >
					<ImageWrapper onClick={onClick} checked={checked} image={imageSource} />
					<CheckboxLabel htmlFor={"form" + index} checked={checked} onClick={onClick} required={required}>
						<input {...input} type="checkbox" className="custom-checkbox" id={"form" + index} checked={checked} onClick={onClick} />
					</CheckboxLabel>
					<p>{tiltText}</p>
				</StyledCheckboxInput>
				{touched && error && <ErrorMessageTextComponent>{error}</ErrorMessageTextComponent>} 

				{/* {touched && error && <ErrorMessageTextComponent2><p>{error}</p></ErrorMessageTextComponent2>} */}
				{/* {touched &&((error && <ErrorValidation> <ErrorNone> {error}</ErrorNone>pole wymagane</ErrorValidation>))} */}
			</CheckboxRelativeWrapper>

		</>

	)
}

export default CustomCheckboxTiltReduxform;
