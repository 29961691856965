import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { Header } from "../common/Header";
import { CategoryHeader } from "../common/CategoryHeader";
import PreviousStepButton from "../common/PreviousStepButton";
import NextStepButton from "../common/NextStepButton";
import { connect, useDispatch, useSelector } from "react-redux"
import { ContentWrapper } from "../common/ContentWrapper";
import { navigate } from "gatsby";
import { NavigationButtonsWrapper } from "../common/NavigationButtonsWrapper";
import {
	whatInsuranceCoverPictograms,
	locksAmount,
	whatInsuranceCoverTooltip,
	whatInsuranceSecureTooltip,
	appliedSecurityTooltip,
	multiValveLockAmountTooltip,
} from "../../dataSources/Page4DataSource";
import RandomEvent from "../../images/WhatPolicyShouldProtect/zdarzenie_losowe.svg";
import Theft from "../../images/WhatPolicyShouldProtect/kradziez.svg";
import Flood from "../../images/WhatPolicyShouldProtect/powodz.svg";
import Vandalism from "../../images/WhatPolicyShouldProtect/wandalizm_dewastacja.svg";
import Intercom from "../../images/SecurityItemsInProperty/domofon.svg"
import LocalAlarm from "../../images/SecurityItemsInProperty/alarm_lokalny.svg"
import AlarmWithIntervention from "../../images/SecurityItemsInProperty/alarm_interwencja.svg";
import ConstantSupervision from "../../images/SecurityItemsInProperty/staly_dozow_fizyczny.svg"
import SecurityDoors from "../../images/SecurityItemsInProperty/drzwi_antywlamaniowe.svg"

import CertifiedLocks from "../../images/NewPictograms/certifiedLocks.svg"
import GlassAndGlassElements from "../../images/NewPictograms/glassAndGlassElements.svg"
import OutbuildingUnderConstruction from "../../images/NewPictograms/outbuildingUnderConstruction.svg"
import BuildingAndComponentsUnderConstruction from "../../images/NewPictograms/buildingAndFixedElementsUnderConstruction.svg"
import FlatWithPermanentElements from "../../images/NewPictograms/flatWithPermanentElements.svg"
import { Slide } from "react-reveal";
import {
	BlueHint, calendarDateFormat, currentDate, ErrorMessageText, HeaderAndQuestionMarkWrapper,
	HeaderAndRadioButtonsWrapper, InvisibleWrapper,
	RadioButtonsWrapper,
	TabWrapper
} from "../sharedComponents/SharedComponents";
import CircleWithQuestionMark from "../tooltip/CircleWithQuestionMark";
import { Field, formValueSelector, reduxForm } from "redux-form";
import CustomDatepickerReduxForm from "../commonReduxFormComponents/CustomDatepickerReduxForm";
import CustomCheckboxTiltReduxform from "../commonReduxFormComponents/CustomCheckboxTiltReduxForm";
import CustomCheckboxTiltReduxform2 from "../commonReduxFormComponents/CustomCheckboxTiltReduxForm2";
import CustomCheckboxTiltReduxform3 from "../commonReduxFormComponents/CustomCheckboxTiltReduxForm3";
import SingleRadioButtonReduxForm from "../commonReduxFormComponents/SingleRadioButtonReduxForm";
import {sendDataToFortWebApiAction} from "../../state/sendDataToFortWebApi";

const CheckboxesWrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	width: 100%;
	//margin-top: -15px;
	margin-bottom: 50px;
	flex-wrap: wrap;
	&.margin-top {
		margin-top: -48px;
	}
	div { 
		div {
			&:nth-child(2n) {
				margin-right: 0;
			} 
	}
	}
	@media(min-width: 414px) {
		justify-content: flex-start;
		div { 
			div{
				&:nth-child(2n) {
					margin-right: 10px;
				}
				&:nth-child(3n) {
					margin-right: 0;
				}
			}
		}
	}
	@media(min-width: 535px) {
		justify-content: flex-start;
		div { 
			div {
				&:nth-child(2n) {
					margin-right: 16px;
				}
				&:nth-child(3n) {
					margin-right: 16px;
				}
			} 
		}
	}
	@media(min-width: 768px) {
		div { 
			div {
				&:nth-child(2n) {
					margin-right: 20px;
				}
				&:nth-child(3n) {
					margin-right: 20px;
				}
				&:nth-child(4n) {
					margin-right: 0;
				}
				&:nth-child(5n) {
					margin-right: 20px;
				}
				&:nth-child(6n) {
					margin-right: 20px;
				}
			} 
		}
	}
	@media(min-width: 928px) {
		div { 
			div {
				&:nth-child(4n) {
					margin-right: 20px;
				}
			}
		}
	}
	@media(min-width: 1024px) {
		margin-bottom: 60px;
	}
`;




let CleanFourthStep = ({ handleSubmit, value, load, pristine, reset, submitting, houseType,
	roofType, wallsConstructionType, propertyForRent, propertyArea, buildYearLabel,
	propertyType, change, city, economicActivity, assignmentToBank, yearsPropertyWasInsured,
	lastInsuranceAgency, damageAmountDuringInsurancePeriod,
	floodDamageAmount, multiValveLockAmount }) => {
	const dispatch = useDispatch()
	const formReducer = useSelector(state => state.form)


	// alert(propertyType);
	// console.log(formReducer?.propertyForm?.values)
	const commonFormReducerValues = (formReducer?.propertyForm?.values?.home) || "";
	console.log(commonFormReducerValues);
	// console.log(localStorage.getItem('propertyForm'))
	// console.log(commonFormReducerValues.wallsAndPermanentElementsInsuranceCovered)
	// console.log(commonFormReducerValues.equipmentInsuranceCovered)
	const [isWallAndPermanentElementsInsuranceCovered, toggleWallsAndPermanentElements] = useState(commonFormReducerValues?.wallsAndPermanentElementsInsuranceCovered);
	const [equipmentInsuranceCovered, toggleEquipment] = useState(commonFormReducerValues?.equipmentInsuranceCovered);
	const [constructionMaterialsInsuranceCovered, toggleConstructionMaterials] = useState(commonFormReducerValues?.constructionMaterialsInsuranceCovered);
	const [glassAndGlassElementsCovered, toggleGlassAndGlassElements] = useState(commonFormReducerValues?.glassAndGlassElementsCovered);

	const [buildingAndComponentsUnderConstruction, toggleBuildingAndComponentsUnderConstruction] = useState(commonFormReducerValues?.buildingAndComponentsUnderConstruction);
	const [outbuildingUnderConstruction, toggleOutbuildingUnderConstruction] = useState(commonFormReducerValues?.outbuildingUnderConstruction);

	const [farmInsuranceCoverageObligatory, toggleObligatoryInsurance] = useState(commonFormReducerValues?.farmInsuranceCoverageObligatory);
	const [farmInsuranceCoverageVoluntary, toggleVoluntaryInsurance] = useState(commonFormReducerValues?.farmInsuranceCoverageVoluntary);

	const [isFlatWithPermanentElements, toggleFlatWithPermanentElements] = useState(commonFormReducerValues?.flatWithPermanentElements);
	const [isFixedItems, toggleFixedItems] = useState(commonFormReducerValues?.fixedItems);
	const [isHouseholdGoods, toggleHouseholdGoods] = useState(commonFormReducerValues?.householdGoods);


	const handleWallsAndPermanentElements = () => {
		toggleWallsAndPermanentElements(!isWallAndPermanentElementsInsuranceCovered);
		dispatch(change("home[wallsAndPermanentElementsInsuranceCovered]", !isWallAndPermanentElementsInsuranceCovered));
	}
	const handleEquipmentInsuranceCovered = () => {
		toggleEquipment(!equipmentInsuranceCovered);
		dispatch(change("home[equipmentInsuranceCovered]", !equipmentInsuranceCovered));
		dispatch(change("home[constructionMaterialsInsuranceCovered]", false));
	}
	const handleglassAndGlassElementsCovered = () => {
		toggleGlassAndGlassElements(!glassAndGlassElementsCovered);
		dispatch(change("home[glassAndGlassElementsCovered]", !glassAndGlassElementsCovered));
		dispatch(change("home[constructionMaterialsInsuranceCovered]", false));
	}


	const handleBuildingAndComponentsUnderConstruction = () => {
		toggleBuildingAndComponentsUnderConstruction(!buildingAndComponentsUnderConstruction);
		dispatch(change("home[buildingAndComponentsUnderConstruction]", !buildingAndComponentsUnderConstruction));
		dispatch(change("home[constructionMaterialsInsuranceCovered]", false));
		dispatch(change("home[outbuildingUnderConstruction]", false));
	}
	const handleOutbuildingUnderConstruction = () => {
		toggleOutbuildingUnderConstruction(!outbuildingUnderConstruction);
		dispatch(change("home[outbuildingUnderConstruction]", !outbuildingUnderConstruction));
		dispatch(change("home[buildingAndComponentsUnderConstruction]", false));
		dispatch(change("home[constructionMaterialsInsuranceCovered]", false));
	}
	const handleConstructionMaterialsInsuranceCovered = () => {
		toggleConstructionMaterials(!constructionMaterialsInsuranceCovered);
		dispatch(change("home[constructionMaterialsInsuranceCovered]", !constructionMaterialsInsuranceCovered));
		dispatch(change("home[equipmentInsuranceCovered]", false));
		dispatch(change("home[buildingAndComponentsUnderConstruction]", false));
		dispatch(change("home[outbuildingUnderConstruction]", false));
	}





	const handleFarmObligatoryInsuranceCoverage = () => {
		toggleObligatoryInsurance(!farmInsuranceCoverageObligatory);
		dispatch(change("home[farmInsuranceCoverageObligatory]", !farmInsuranceCoverageObligatory));
		dispatch(change("home[wallsAndPermanentElementsInsuranceCovered]", false));
	}
	const handleFarmVoluntaryInsuranceCoverage = () => {
		toggleVoluntaryInsurance(!farmInsuranceCoverageVoluntary);
		dispatch(change("home[farmInsuranceCoverageVoluntary]", !farmInsuranceCoverageVoluntary));
		dispatch(change("home[wallsAndPermanentElementsInsuranceCovered]", false));
		dispatch(change("home[equipmentInsuranceCovered]", false));
	}

	const handleFlatWithPermanentElements = () => {
		toggleFlatWithPermanentElements(!isFlatWithPermanentElements);
		dispatch(change("home[flatWithPermanentElements]", !isFlatWithPermanentElements));
	}

	const handleFixedItems = () => {
		toggleFixedItems(!isFixedItems);
		dispatch(change("home[fixedItems]", !isFixedItems));
	}

	const handleHouseholdGoods = () => {
		toggleHouseholdGoods(!isHouseholdGoods);
		dispatch(change("home[householdGoods]", !isHouseholdGoods));
	}



	const [isRandomEventInsured, toggleRandomEvent] = useState(commonFormReducerValues.isRandomEventInsured);
	const [isTheftInsured, toggleTheft] = useState(commonFormReducerValues?.isTheftInsured);
	const [isFloodInsured, toggleFlood] = useState(commonFormReducerValues?.isFloodInsured);
	const [isVandalismInsured, toggleVandalism] = useState(commonFormReducerValues?.isVandalismInsured);
	// const [isGlassItemInsured, toggleGlassItem] = useState(commonFormReducerValues?.isGlassItemInsured);

	const handleRandomEvent = () => {
		toggleRandomEvent(!isRandomEventInsured);
		dispatch(change("home[isRandomEventInsured]", !isRandomEventInsured));
	}
	const handleTheft = () => {
		toggleTheft(!isTheftInsured);
		dispatch(change("home[isTheftInsured]", !isTheftInsured));
	}
	const handleFlood = () => {
		toggleFlood(!isFloodInsured);
		dispatch(change("home[isFloodInsured]", !isFloodInsured));
	}
	const handleVandalism = () => {
		toggleVandalism(!isVandalismInsured);
		dispatch(change("home[isVandalismInsured]", !isVandalismInsured));
	}
	// const handleGlassItem = () => {
	// 	toggleGlassItem(!isGlassItemInsured);
	// 	dispatch(change("isGlassItemInsured", !isGlassItemInsured));
	// }

	const [isIntercom, toggleIntercom] = useState(commonFormReducerValues?.isIntercom);
	const [isLocalAlarm, toggleLocalAlarm] = useState(commonFormReducerValues?.isLocalAlarm);
	const [isAlarmWithIntervention, toggleAlarmWithIntervention] = useState(commonFormReducerValues?.isAlarmWithIntervention);
	const [isConstantSupervision, toggleConstantSupervision] = useState(commonFormReducerValues?.isConstantSupervision);
	const [isSecurityDoor, toggleSecurityDoor] = useState(commonFormReducerValues?.isSecurityDoor);
	// const [isMonitoring, toggleMonitoring] = useState(commonFormReducerValues?.isMonitoring);
	// const [isWindowSecurity, toggleWindowSecurity] = useState(commonFormReducerValues?.isWindowSecurity);
	const [isCertifiedLock, toggleCertifiedLock] = useState(commonFormReducerValues?.certifiedLock);

	const handleIntercom = () => {
		toggleIntercom(!isIntercom);
		dispatch(change("home[isIntercom]", !isIntercom));
	}
	const handleLocalAlarm = () => {
		toggleLocalAlarm(!isLocalAlarm);
		dispatch(change("home[isLocalAlarm]", !isLocalAlarm));
	}
	const handleAlarmWithIntervention = () => {
		toggleAlarmWithIntervention(!isAlarmWithIntervention);
		dispatch(change("home[isAlarmWithIntervention]", !isAlarmWithIntervention));
	}
	const handleConstantSupervision = () => {
		toggleConstantSupervision(!isConstantSupervision);
		dispatch(change("home[isConstantSupervision]", !isConstantSupervision));
	}
	const handleSecurityDoors = () => {
		toggleSecurityDoor(!isSecurityDoor);
		dispatch(change("home[isSecurityDoor]", !isSecurityDoor));
	}
	// const handleMonitoring = () => {
	// 	toggleMonitoring(!isMonitoring);
	// 	dispatch(change("isMonitoring", !isMonitoring));
	// }
	// const handleWindowSecurity = () => {
	// 	toggleWindowSecurity(!isWindowSecurity);
	// 	dispatch(change("isWindowSecurity", !isWindowSecurity));
	// }

	const handleCertifiedLock = () => {
		toggleCertifiedLock(!isCertifiedLock);
		dispatch(change("home[isCertifiedLock]", !isCertifiedLock));
	}


	const [whatInsuranceCoverTooltipVisible, toogleWhatInsuranceCoverVisibility] = useState(false)
	const handleWhatInsuranceCoverTooltipVisibility = () => {
		toogleWhatInsuranceCoverVisibility(!whatInsuranceCoverTooltipVisible)
		toogleWhatInsuranceSecureVisibility(false)
		toogleMultiValveLockAmountVisibility(false)
		toogleCertifiedLockVisibility(false)
		toogleAppliedSecurityVisibility(false)
	}
	const [whatInsuranceSecureTooltipVisible, toogleWhatInsuranceSecureVisibility] = useState(false)
	const handleWhatInsuranceSecureTooltipVisibility = () => {
		toogleWhatInsuranceSecureVisibility(!whatInsuranceSecureTooltipVisible)
		toogleWhatInsuranceCoverVisibility(false)
		toogleMultiValveLockAmountVisibility(false)
		toogleCertifiedLockVisibility(false)
		toogleAppliedSecurityVisibility(false)
	}
	const [multiValveLockAmountVisible, toogleMultiValveLockAmountVisibility] = useState(false)
	const handleMultiValveLockAmountTooltipVisibility = () => {
		toogleMultiValveLockAmountVisibility(!multiValveLockAmountVisible)
		toogleWhatInsuranceCoverVisibility(false)
		toogleWhatInsuranceSecureVisibility(false)
		toogleCertifiedLockVisibility(false)
		toogleAppliedSecurityVisibility(false)
	}
	const [certifiedLockVisible, toogleCertifiedLockVisibility] = useState(false)
	const handleCertifiedLockTooltipVisibility = () => {
		toogleCertifiedLockVisibility(!certifiedLockVisible)
		toogleWhatInsuranceCoverVisibility(false)
		toogleWhatInsuranceSecureVisibility(false)
		toogleMultiValveLockAmountVisibility(false)
		toogleAppliedSecurityVisibility(false)
	}
	const [appliedSecurityTooltipVisible, toogleAppliedSecurityVisibility] = useState(false)
	const handleAppliedSecurityTooltipVisibility = () => {
		toogleAppliedSecurityVisibility(!appliedSecurityTooltipVisible)
		toogleWhatInsuranceCoverVisibility(false)
		toogleWhatInsuranceSecureVisibility(false)
		toogleMultiValveLockAmountVisibility(false)
		toogleCertifiedLockVisibility(false)
	}





	// useEffect(() => {
	// 	if (typeof window !== "undefined") {
	// 		const viewportWidth = window.innerWidth
	// 	}
	// });



	return (



		<TabWrapper>
			<Header className="tablet-hidden">Zakres ubezpieczenia</Header>
			<BlueHint className="tablet-hidden">wypełnij formularz</BlueHint>
			<ContentWrapper>
				<form onSubmit={handleSubmit}>
					<Field
						name="home[insuranceBeginningDate]"
						component={CustomDatepickerReduxForm}
						type="date"
						// validate={validate.isRequired}
						isFirst={true}
						label={"Początek okresu ubezpieczenia"}
						placeholderText={"Wybierz datę"}
						showMonthDropdown
						showYearDropdown
						dropdownMode="scroll"
						minDate={currentDate}
						locale={'pl'}
						dateFormat={calendarDateFormat}
						pickerClassName="datepicker"
					/>




					<InvisibleWrapper isVisible={propertyType === "Dom" || propertyType === "Mieszkanie" || propertyType === "Dom w budowie" || propertyType === "Gospodarstwo rolne"}>
						{propertyType !== "Gospodarstwo rolne" && <>
							<HeaderAndQuestionMarkWrapper>
								<CategoryHeader className="margin-top">Przedmiot ubezpieczenia</CategoryHeader>
								<CircleWithQuestionMark
									showCircle={true}
									isFirstOnPage={true}
									isMarginTop={true}
									isWindowVisible={whatInsuranceCoverTooltipVisible}
									onClick={handleWhatInsuranceCoverTooltipVisibility}
									tooltipHeader={whatInsuranceCoverTooltip.header}
									tooltipDescription={whatInsuranceCoverTooltip.description} />
							</HeaderAndQuestionMarkWrapper>
							<CheckboxesWrapper>
								{propertyType === "Mieszkanie" &&
									<Field
										name="home[flatWithPermanentElements]"
										component={CustomCheckboxTiltReduxform}
										index={30}
										imageSource={FlatWithPermanentElements}
										checked={isFlatWithPermanentElements}
										tiltText="Mieszkanie wraz ze stałymi elementami"
										onClick={handleFlatWithPermanentElements}
									/>}
								{(propertyType !== "Mieszkanie" && propertyType !== "Dom w budowie") &&
									<Field
										name="home[wallsAndPermanentElementsInsuranceCovered]"
										component={CustomCheckboxTiltReduxform}
										checkboxText="Mury i elementy stałe"
										// validate={validate.isRequiredCheckbox}
										index={31}
										imageSource={whatInsuranceCoverPictograms[0]}
										checked={isWallAndPermanentElementsInsuranceCovered}
										tiltText="Mury i elementy stałe"
										onClick={handleWallsAndPermanentElements}
									/>}




								{propertyType === "Dom w budowie" &&
									<Field
										name="home[buildingAndComponentsUnderConstruction]"
										component={CustomCheckboxTiltReduxform3}
										// checkboxText="Mury i elementy stałe"
										// validate={validate.isRequiredCheckbox}
										index={61}
										imageSource={BuildingAndComponentsUnderConstruction}
										checked={buildingAndComponentsUnderConstruction}
										// tiltText={viewportWidth > 768 ? "Budynek i stałe elementy w budowie" : "Budynek i stałe elementy"}
										tiltText="Budynek i stałe elementy w budowie"
										onClick={handleBuildingAndComponentsUnderConstruction}
									/>
								}
								{propertyType === "Dom w budowie" &&
									<Field
										name="home[outbuildingUnderConstruction]"
										component={CustomCheckboxTiltReduxform3}
										// checkboxText="Mury i elementy stałe"
										// validate={validate.isRequiredCheckbox}
										index={62}
										imageSource={OutbuildingUnderConstruction}
										checked={outbuildingUnderConstruction}
										// tiltText={viewportWidth > 768 ? "Budynek gospodarczy w budowie" : "Budynek gospodarczy"}
										tiltText="Budynek gospodarczy w budowie"
										onClick={handleOutbuildingUnderConstruction}
									/>
								}
								{propertyType === "Dom w budowie" &&
									<Field
										name="home[constructionMaterialsInsuranceCovered]"
										component={CustomCheckboxTiltReduxform}
										// checkboxText="Mury i elementy stałe"
										// validate={validate.isRequiredCheckbox}
										index={63}
										imageSource={whatInsuranceCoverPictograms[2]}
										checked={constructionMaterialsInsuranceCovered}
										tiltText="Materiały budowlane"
										onClick={handleConstructionMaterialsInsuranceCovered}
									/>
								}




								{propertyType !== "Dom w budowie" &&
									<Field
										name="home[equipmentInsuranceCovered]"
										component={CustomCheckboxTiltReduxform}
										// checkboxText="Mury i elementy stałe"
										// validate={validate.isRequiredCheckbox}
										index={33}
										imageSource={whatInsuranceCoverPictograms[1]}
										checked={equipmentInsuranceCovered}
										tiltText="Wyposażenie"
										onClick={handleEquipmentInsuranceCovered}
									/>}
								{propertyType !== "Dom w budowie" &&
									<Field
										name="home[glassAndGlassElementsCovered]"
										component={CustomCheckboxTiltReduxform}
										// checkboxText="Mury i elementy stałe"
										// validate={validate.isRequiredCheckbox}
										index={34}
										imageSource={GlassAndGlassElements}
										checked={glassAndGlassElementsCovered}
										tiltText="Szyby i elementy stałe"
										onClick={handleglassAndGlassElementsCovered}
									/>}
							</CheckboxesWrapper></>}
						{propertyType === "Gospodarstwo rolne" && <>
							<CategoryHeader className="margin-top">Zakres ubezpieczenia</CategoryHeader>
							<CheckboxesWrapper>
								<Field
									name="home[farmInsuranceCoverageObligatory]"
									component={CustomCheckboxTiltReduxform}
									// checkboxText="Mury i elementy stałe"
									// validate={validate.isRequiredCheckbox}
									index={41}
									imageSource={whatInsuranceCoverPictograms[0]}
									checked={farmInsuranceCoverageObligatory}
									tiltText="Obowiązkowe"
									onClick={handleFarmObligatoryInsuranceCoverage}
								/>
								<Field
									name="home[farmInsuranceCoverageVoluntary]"
									component={CustomCheckboxTiltReduxform}
									// checkboxText="Mury i elementy stałe"
									// validate={validate.isRequiredCheckbox}
									index={42}
									imageSource={whatInsuranceCoverPictograms[1]}
									checked={farmInsuranceCoverageVoluntary}
									tiltText="Dobrowolne (sprzęt, wyposażenie)"
									onClick={handleFarmVoluntaryInsuranceCoverage}
								/>
							</CheckboxesWrapper>
						</>}
					</InvisibleWrapper>


					<InvisibleWrapper isVisible={propertyType !== "Gospodarstwo rolne" && (isWallAndPermanentElementsInsuranceCovered || equipmentInsuranceCovered || isFlatWithPermanentElements || isFixedItems || isHouseholdGoods || glassAndGlassElementsCovered || buildingAndComponentsUnderConstruction || outbuildingUnderConstruction || constructionMaterialsInsuranceCovered)}>
						<Slide top duration="300">
							<HeaderAndQuestionMarkWrapper className="move-top">
								<CategoryHeader className="margin-top">Zakres ubezpieczenia</CategoryHeader>
								<CircleWithQuestionMark
									showCircle={true}
									isMarginTop={true}
									isWindowVisible={whatInsuranceSecureTooltipVisible}
									onClick={handleWhatInsuranceSecureTooltipVisibility}
									tooltipHeader={whatInsuranceSecureTooltip.header}
									tooltipDescription={whatInsuranceSecureTooltip.description} />
							</HeaderAndQuestionMarkWrapper>
							<CheckboxesWrapper>
								<Field
									name="home[isRandomEventInsured]"
									component={CustomCheckboxTiltReduxform2}
									// checkboxText="Mury i elementy stałe"
									// validate={validate.isRequiredCheckbox}
									index={1}
									imageSource={RandomEvent}
									checked={isRandomEventInsured}
									tiltText="Zdarzenia losowe"
									onClick={handleRandomEvent}
								/>
								<Field
									name="home[isTheftInsured]"
									component={CustomCheckboxTiltReduxform2}
									// checkboxText="Mury i elementy stałe"
									// validate={validate.isRequiredCheckbox}
									index={2}
									imageSource={Theft}
									checked={isTheftInsured}
									tiltText="Kradzież"
									onClick={handleTheft}
								/>
								<Field
									name="home[isFloodInsured]"
									component={CustomCheckboxTiltReduxform2}
									// checkboxText="Mury i elementy stałe"
									// validate={validate.isRequiredCheckbox}
									index={3}
									imageSource={Flood}
									checked={isFloodInsured}
									tiltText="Powódź"
									onClick={handleFlood}
								/>
								<Field
									name="home[isVandalismInsured]"
									component={CustomCheckboxTiltReduxform2}
									// checkboxText="Mury i elementy stałe"
									// validate={validate.isRequiredCheckbox}
									index={4}
									imageSource={Vandalism}
									checked={isVandalismInsured}
									tiltText="Wandalizm, dewastacja"
									onClick={handleVandalism}
								/>

							</CheckboxesWrapper>
						</Slide>
					</InvisibleWrapper>
					<InvisibleWrapper isVisible={((isWallAndPermanentElementsInsuranceCovered || equipmentInsuranceCovered || isFlatWithPermanentElements || isFixedItems || isHouseholdGoods || buildingAndComponentsUnderConstruction || outbuildingUnderConstruction || constructionMaterialsInsuranceCovered) && (isRandomEventInsured && isTheftInsured)) || ((propertyType === "Gospodarstwo rolne" && (farmInsuranceCoverageObligatory || farmInsuranceCoverageVoluntary)))}>
						<Slide top duration="300">
							<HeaderAndQuestionMarkWrapper>
								<CategoryHeader className="with-checkbox">Zabezpieczenia</CategoryHeader>
								<CircleWithQuestionMark
									showCircle={true}
									isWindowVisible={appliedSecurityTooltipVisible}
									onClick={handleAppliedSecurityTooltipVisibility}
									tooltipHeader={appliedSecurityTooltip.header}
									tooltipDescription={appliedSecurityTooltip.description} />
							</HeaderAndQuestionMarkWrapper>
							<CheckboxesWrapper>
								<Field
									name="home[isIntercom]"
									component={CustomCheckboxTiltReduxform}
									// checkboxText="Mury i elementy stałe"
									// validate={validate.isRequiredCheckbox}
									index={11}
									imageSource={Intercom}
									checked={isIntercom}
									tiltText="Domofon"
									onClick={handleIntercom}
								/>
								<Field
									name="home[isConstantSupervision]"
									component={CustomCheckboxTiltReduxform}
									// checkboxText="Mury i elementy stałe"
									// validate={validate.isRequiredCheckbox}
									index={12}
									imageSource={ConstantSupervision}
									checked={isConstantSupervision}
									tiltText="Stały dozór"
									onClick={handleConstantSupervision}
								/>
								<Field
									name="home[isLocalAlarm]"
									component={CustomCheckboxTiltReduxform}
									// checkboxText="Mury i elementy stałe"
									// validate={validate.isRequiredCheckbox}
									index={13}
									imageSource={LocalAlarm}
									checked={isLocalAlarm}
									tiltText="Alarm lokalny"
									onClick={handleLocalAlarm}
								/>
								<Field
									name="home[isAlarmWithIntervention]"
									component={CustomCheckboxTiltReduxform}
									// checkboxText="Mury i elementy stałe"
									// validate={validate.isRequiredCheckbox}
									index={14}
									imageSource={AlarmWithIntervention}
									checked={isAlarmWithIntervention}
									tiltText="Alarm z powiadomieniem"
									onClick={handleAlarmWithIntervention}
								/>
								<Field
									name="home[isSecurityDoor]"
									component={CustomCheckboxTiltReduxform}
									// checkboxText="Mury i elementy stałe"
									// validate={validate.isRequiredCheckbox}
									index={15}
									imageSource={SecurityDoors}
									checked={isSecurityDoor}
									tiltText="Drzwi antywłamaniowe"
									onClick={handleSecurityDoors}
								/>
								<Field
									name="home[certifiedLock]"
									component={CustomCheckboxTiltReduxform}
									// checkboxText="Mury i elementy stałe"
									// validate={validate.isRequiredCheckbox}
									index={16}
									imageSource={CertifiedLocks}
									checked={isCertifiedLock}
									tiltText="Zamki atestowane"
									onClick={handleCertifiedLock}
								/>

							</CheckboxesWrapper></Slide>
					</InvisibleWrapper>
					<InvisibleWrapper isVisible={((isWallAndPermanentElementsInsuranceCovered || equipmentInsuranceCovered || isFlatWithPermanentElements || isFixedItems || isHouseholdGoods || buildingAndComponentsUnderConstruction || outbuildingUnderConstruction || constructionMaterialsInsuranceCovered) && (isRandomEventInsured && isTheftInsured) || (farmInsuranceCoverageObligatory || farmInsuranceCoverageVoluntary))}>
						<Slide top duration="300">
							<HeaderAndRadioButtonsWrapper>
								<HeaderAndQuestionMarkWrapper>
									<CategoryHeader className="radio-buttons">Liczba zamków wielozastawkowych w drzwiach wejściowych</CategoryHeader>
									<CircleWithQuestionMark
										showCircle={true}
										isRadioButton={true}
										isWindowVisible={multiValveLockAmountVisible}
										onClick={handleMultiValveLockAmountTooltipVisibility}
										tooltipHeader={multiValveLockAmountTooltip.header}
										tooltipDescription={multiValveLockAmountTooltip.description} />
								</HeaderAndQuestionMarkWrapper>
								<RadioButtonsWrapper>
									{locksAmount.map(({ label, value }) => (
										<Field name="home[multiValveLockAmount]"
											id={value}
											key={value}
											type="radio"
											component={SingleRadioButtonReduxForm}
											amountName={label}
											htmlId={'multivalve-lock-amount-' + value}
											activeId={multiValveLockAmount}
											onClick={(val, label) => {
												change('home[multiValveLockAmount]', val)
												console.log(val)
												// setFieldValue("propertyForRent", val)
												// setFieldValue("propertyForRentLabel", label)
											}}
										/>
									))}
								</RadioButtonsWrapper>
							</HeaderAndRadioButtonsWrapper>
						</Slide>
					</InvisibleWrapper>
					<NavigationButtonsWrapper className="whole-margin">
						<PreviousStepButton type="button" isVisible={true} textBtn={"Poprzedni krok"} to="/step-3" />
						<NextStepButton type="submit"
							isDisabled={submitting}
							isVisible={true} textBtn={"DALEJ"} />
					</NavigationButtonsWrapper>
				</form>
			</ContentWrapper>
		</TabWrapper>
	)
};

const validate = values => {
	const errors = {
		"lead": {},
		"person": {},
		"home": {
			"address": {},
		},
	};
	if (!values?.home?.insuranceBeginningDate) {
		errors.home.insuranceBeginningDate = 'pole wymagane';
	}

	if (!values?.home?.wallsAndPermanentElementsInsuranceCovered && !values?.home?.equipmentInsuranceCovered && !values?.home?.constructionMaterialsInsuranceCovered && !values?.home?.glassAndGlassElementsCovered) {
		errors.home.wallsAndPermanentElementsInsuranceCovered = 'pole wymagane';
	}

	if (values?.home?.propertyType === "Dom w budowie") {
		if (!values?.home?.buildingAndComponentsUnderConstruction && !values?.home?.outbuildingUnderConstruction && !values?.home?.constructionMaterialsInsuranceCovered) {
			errors.home.buildingAndComponentsUnderConstruction = "pole wymagane";
		}
	}


	if (values?.home?.propertyType !== "Gospodarstwo rolne") {
		if (!values?.home?.isRandomEventInsured && !values?.home?.isTheftInsured && !values?.home?.isFloodInsured && !values?.home?.isVandalismInsured) {
			errors.home.isRandomEventInsured = 'pole wymagane';
		}
	}

	if (values?.home?.propertyType === "Mieszkanie") {
		if (!values?.home?.flatWithPermanentElements && !values?.home?.equipmentInsuranceCovered && !values?.home?.glassAndGlassElementsCovered) {
			errors.home.flatWithPermanentElements = "pole wymagane";
		}
	}

	if (values?.home?.propertyType === "Gospodarstwo rolne") {
		if (!values?.home?.farmInsuranceCoverageObligatory && !values?.home?.farmInsuranceCoverageVoluntary) {
			errors.home.farmInsuranceCoverageObligatory = "pole wymagane";
		}
	}


	if ((values?.home?.isWallAndPermanentElementsInsuranceCovered || values?.home?.equipmentInsuranceCovered || values?.home?.isFlatWithPermanentElements || values?.home?.glassAndGlassElementsCovered || values?.home?.isFixedItems || values?.home?.isHouseholdGoods || values?.home?.buildingAndComponentsUnderConstruction || values?.home?.outbuildingUnderConstruction || values?.home?.constructionMaterialsInsuranceCovered) && (values?.home?.isRandomEventInsured && values?.home?.isTheftInsured)) {
		if (!values?.home?.multiValveLockAmount) {
			errors.home.multiValveLockAmount = 'pole wymagane';
		}
	}

	if ((values?.home?.propertyType === "Gospodarstwo rolne") && (values?.home?.farmInsuranceCoverageObligatory || values?.home?.farmInsuranceCoverageVoluntary)) {
		if (!values?.home?.multiValveLockAmount) {
			errors.home.multiValveLockAmount = 'pole wymagane';
		}
	}

	console.log('page4blank ' + JSON.stringify(errors))
	return errors;
};

const onSubmit = (values, dispatch, formReducer) => {
	localStorage.setItem('propertyForm', JSON.stringify(values));
	// registerField('propertyForm', 'street', 'string')
	// localStorage.setItem('form-data', JSON.parse(values));
	// alert(JSON.stringify(values))
	// alert(JSON.stringify(formReducer))
	console.log(formReducer)
	// dispatch(sendDataToFortWebApiAction(JSON.stringify(values)))
	// dispatch(togglePopupVisibility(true))
	dispatch(sendDataToFortWebApiAction(values))
	// dispatch(sendDataToFortWebApiAction(JSON.stringify(formReducer.values)))
	navigate('/step-5')
	console.log(JSON.stringify(values))
}

CleanFourthStep = reduxForm({
	form: 'propertyForm',
	destroyOnUnmount: false,
	keepDirtyOnReinitialize: true,
	// forceUnregisterOnUnmount: true,
	validate,
	onSubmit
})(CleanFourthStep);

const selector = formValueSelector('propertyForm')
CleanFourthStep = connect(state => {
	// can select values individually
	const propertyForRent = selector(state, 'home[propertyForRent]')
	const propertyArea = selector(state, 'home[propertyArea]')
	const propertyType = selector(state, 'home[propertyType]')
	const houseType = selector(state, 'home[houseType]')
	const roofType = selector(state, 'home[roofType]')
	const wallsConstructionType = selector(state, 'home[wallsConstructionType]')
	const buildYearLabel = selector(state, 'buildYearLabel')
	const city = selector(state, 'home[address[city]]')
	const economicActivity = selector(state, 'home[economicActivity]')
	const assignmentToBank = selector(state, 'home[assignmentToBank]')
	const yearsPropertyWasInsured = selector(state, 'home[yearsPropertyWasInsured]')
	const lastInsuranceAgency = selector(state, 'home[lastInsuranceAgency]')
	const damageAmountDuringInsurancePeriod = selector(state, 'home[damageAmountDuringInsurancePeriod]')
	const floodDamageAmount = selector(state, 'home[floodDamageAmount]')
	const multiValveLockAmount = selector(state, 'home[multiValveLockAmount]')
	// const certifiedLock = selector(state, 'certifiedLock')

	// values: getFormValues('propertyForm')(state)
	// const favoriteColorValue = selector(state, 'favoriteColor')
	// or together as a group
	// const { firstName, lastName } = selector(state, 'firstName', 'lastName')
	return {
		propertyForRent,
		propertyArea,
		propertyType,
		houseType,
		roofType,
		wallsConstructionType,
		buildYearLabel,
		city,
		economicActivity,
		assignmentToBank,
		yearsPropertyWasInsured,
		lastInsuranceAgency,
		damageAmountDuringInsurancePeriod,
		floodDamageAmount,
		multiValveLockAmount,
		// certifiedLock,
		// favoriteColorValue,
		// fullName: `${firstName || ''} ${lastName || ''}`
	}
})(CleanFourthStep)
export default CleanFourthStep;
