import WallsAndPermanentElements from "../images/PropertyTypes/dom_1.svg"
import Equipment from "../images/WhatInsuranceCover/wyposazenie.svg";
import ConstructionMaterials from "../images/WhatInsuranceCover/materialy_budowlane_skladowane_na_budowie.svg";

export const whatInsuranceCoverPictograms = [WallsAndPermanentElements, Equipment, ConstructionMaterials];

export const locksAmount = [{
	"value": "0",
	"label": "0"
},{
	"value": "1",
	"label": "1"
},{
	"value": "2+",
	"label": "2+"
}]

export const whatInsuranceCoverTooltip = {
	"header": "Co powinno byc objęte ubezpieczeniem?",
	"description": "Wybierz \"Mury i elmenty stałe\", jeśli chcesz ubezpieczyć konstrukcję budynku (składają się na nią m.in. ściany nośne, stropy i podłogi) oraz elementy stałe (np.: ściany działowe, instalacje, armatura, stolarka drzwiowa i okienna, stała zabudowa meblowa). Opcja ta jest niedostępna, gdy jesteś najemcą, masz spółdzielczo-lokatorskie prawo własności bądź korzystasz z nieruchomości w ramach umowy użyczenia. W przypadku, gdy potrzebna jest cesja polisy dla banku, ubezpieczenie murów jest obowiązkowe.\n" +
		"\n" +
		"Wybierz \"Wyposażenie\", jeśli planujesz ubezpieczyć wyposażenie - zarówno w zabudowie (np.: stała zabudowa kuchenna, instalacje, szafy wnękowe), jak i wolnostojące (np.: meble, sprzęt AGD, RTV, przedmioty codziennego użytku).\n" +
		"\n" +
		"Garaż oraz piwnicę można ubezpieczyć tylko razem z ubezpieczeniem murów bądź ubezpieczeniem wyposażenia. W przeważającej części ofert ochroną objęte zostaną tylko garaż i piwnica, które są częścią tego samego budynku. Pamiętaj, że tylko w wybranych Towarzystwach ochroną objęte są garaże i piwnice wolnostojące."
}

export const whatInsuranceSecureTooltip = {
	"header": "Od czego powinna chronić polisa",
	"description": "Zdarzenia losowe: to ryzyko pozwala otrzymać odszkodowanie, gdy poniesiesz straty finansowe, spowodowane zdarzeniami, na których zaistnienie nie masz wpływu. Najczęstsze zdarzenia losowe to np.: pożar, powódź, zalanie w wyniku silnych opadów deszczu bądź zniszczenia spowodowane działaniem wiatru. W przypadku, gdy potrzebujesz cesji na rzecz banku, wykupienie tego ryzyka jest obowiązkowe, aby bank zaakceptował polisę.\n" +
		"\n" +
		"Kradzież z włamaniem: to ryzyko pozwala otrzymać odszkodowanie, gdy poniesiesz straty finansowe, spowodowane kradzieżą, włamaniem itp.\n" +
		"\n" +
		"Powódź: to ryzyko pozwala otrzymać odszkodowanie, gdy poniesiesz straty finansowe spowodowane powodzią, podtopieniem, zalaniem gruntowym itp. Wykupienie tego ryzyka jest możliwe tylko razem z ryzykiem Zdarzenia losowe.\n" +
		"\n" +
		"Dewastacja: to ryzyko pozwala otrzymać odszkodowanie, gdy poniesiesz straty finansowe spowodowane dewastacją bądź wandalizmem, np.: gdy ktoś zniszczy ogrodzenie nieruchomości, drzwi bądź elewację. Wykupienie tego ryzyka jest możliwe tylko razem z ryzykiem Zdarzenia losowe.\n" +
		"\n" +
		"Przedmioty szklane: to ryzyko pozwala otrzymać odszkodowanie, gdy stłuczeniu ulegną szklane elementy wyposażenia nieruchomości, np.: szklane płyty grzewcze, lustra, przeszklenia drzwi wewnętrznych itp. Wykupienie tego ryzyka jest możliwe tylko razem z ryzykiem Zdarzenia losowe."
}

export const multiValveLockAmountTooltip = {
	"header": "Zamki wielozastawkowe",
	"description": "Zamek wielozastawkowy to zamek mechaniczny, który posiada więcej niż jedną ruchomą zastawkę. Ilość zastawek w zamku można ustalić na podstawie nacięć profilowanych w kluczu."
}

export const appliedSecurityTooltip = {
	"header": "Zastosowane zabezpieczenia",
	"description": "Domofon - zaznacz, jeśli nieruchomość wyposażono w sprawne urządzenie służące do komunikacji głosowej na małe odległości bez pośrednictwa centrali, w tym wideodomofon.\n" +
		"\n" +
		"\n" +
		"Stały dozór - zaznacz tą odpowiedź, jeśli nieruchomość jest objęta stałym, całodobowym dozorem pełnionym przez pracowników Ubezpieczonego, osoby objęte ubezpieczeniem lub pracowników agencji ochrony mienia.\n" +
		"\n" +
		"\n" +
		"Alarm lokalny - zaznacz, jeśli nieruchomość wyposażono w sprawny system anywłamaniowy wywołujący sygnał dźwiękowy w miejscu ubezpieczenia.\n" +
		"\n" +
		"\n" +
		"Alarm z powiadomieniem - zaznacz, jeśli nieruchomość wyposażono w sprawny system antywłamaniowy obejmujący zasięgiem całą powierzchnię domu lub mieszkania i wywołujący alarm w jednostce policji lub agencji ochrony mienia.\n" +
		"\n" +
		"\n" +
		"Drzwi antywłamaniowe - zaznacz, jeśli wejścia do ubezpieczanej nieruchomości wyposażone są w drzwi konstrukcyjnie wzmocnione, z rozwiązaniami utrudniającymi ich wyważenie lub zniszczenie, drzwi zgodnie z klasą odporności na włamanie co najmniej 3.\n"+
		"\n" +
		"\n" +
		"Zamki atestowane - Zamek atestowany to zamek o podwyższonej odporności na włamanie, posiadający atest uprawnionej instytucji (np.: Instytutu Mechaniki Precyzyjnej, Polskiego Centrum Badań i Certyfikacji).\n"
}
